import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Passions are so different`}<br parentName="p"></br>{`
`}{`That’s what I will say`}<br parentName="p"></br>{`
`}{`You will never count them all`}<br parentName="p"></br>{`
`}{`Even in a few days.`}</p>
    <p>{`What is passion?`}<br parentName="p"></br>{`
`}{`Alexsander asked`}<br parentName="p"></br>{`
`}{`It’s what you love or like`}<br parentName="p"></br>{`
`}{`Fales said.`}</p>
    <p>{`These are famous`}<br parentName="p"></br>{`
`}{`Greek philosophers`}<br parentName="p"></br>{`
`}{`Whom we can call as`}<br parentName="p"></br>{`
`}{`Wisdom officers.`}</p>
    <p>{`Passions …`}<br parentName="p"></br>{`
`}{`It can be your love of days`}<br parentName="p"></br>{`
`}{`It can be to swim in a lake`}<br parentName="p"></br>{`
`}{`It can be something you do`}<br parentName="p"></br>{`
`}{`It can be to watch how flowers bloom.`}</p>
    <p>{`It can be many things,`}<br parentName="p"></br>{`
`}{`Much more`}<br parentName="p"></br>{`
`}{`Like making carpets on the floor.`}</p>
    <p>{`Passions make you go straight on your way,`}<br parentName="p"></br>{`
`}{`They motivate you,`}<br parentName="p"></br>{`
`}{`That's what I will say.`}<br parentName="p"></br>{`
`}{`They challenge you,`}<br parentName="p"></br>{`
`}{`And only those for whom it is important`}<br parentName="p"></br>{`
`}{`Go forward.`}</p>
    <p>{`There was a famous author,`}<br parentName="p"></br>{`
`}{`In old Great Britain,`}<br parentName="p"></br>{`
`}{`By whom this famous poem`}<br parentName="p"></br>{`
`}{`Has been written.`}<br parentName="p"></br>{`
`}{`William Shakespeare,`}<br parentName="p"></br>{`
`}{`He is known all over the world.`}<br parentName="p"></br>{`
`}{`He wrote tragic stories`}<br parentName="p"></br>{`
`}{`One happened near Rome.`}</p>
    <p>{`The story I was talking about`}<br parentName="p"></br>{`
`}{`Actually should be crowned`}<br parentName="p"></br>{`
`}{`As a very famous one`}<br parentName="p"></br>{`
`}{`It is about Romeo and Julliette`}<br parentName="p"></br>{`
`}{`Young people that had fell in love`}<br parentName="p"></br>{`
`}{`But as they were from families,`}<br parentName="p"></br>{`
`}{`That had a war`}<br parentName="p"></br>{`
`}{`They married secretly.`}</p>
    <p>{`But soon Jullietta found out`}<br parentName="p"></br>{`
`}{`That her parents will marry her`}<br parentName="p"></br>{`
`}{`So she pretends that she is dead`}<br parentName="p"></br>{`
`}{`And sends a message to Romeo`}<br parentName="p"></br>{`
`}{`Which he didn’t get.`}</p>
    <p>{`When Romeo hears about Jullietta’s death`}<br parentName="p"></br>{`
`}{`He came as fast as he was able`}<br parentName="p"></br>{`
`}{`And when he saw her in the coffin`}<br parentName="p"></br>{`
`}{`He took a sword and killed himself.`}</p>
    <p>{`Julietta woke up up in a few seconds`}<br parentName="p"></br>{`
`}{`And when she saw Romeo near, dead`}<br parentName="p"></br>{`
`}{`She took a sword he killed himself`}<br parentName="p"></br>{`
`}{`And killed herself.`}</p>
    <p>{`So where are the passions`}<br parentName="p"></br>{`
`}{`In this story`}<br parentName="p"></br>{`
`}{`You will ask`}</p>
    <p>{`I’ll tell you`}<br parentName="p"></br>{`
`}{`The explanation`}<br parentName="p"></br>{`
`}{`Wouldn’t last`}<br parentName="p"></br>{`
`}{`For long ...`}</p>
    <p>{`I think it is easy to mention`}<br parentName="p"></br>{`
`}{`That we can call their love as passion`}<br parentName="p"></br>{`
`}{`And this will never be old-fashioned.`}<br parentName="p"></br>{`
`}{`It makes you do incredible things`}<br parentName="p"></br>{`
`}{`For yourself and others, too.`}<br parentName="p"></br>{`
`}{`You can’t live without it like the glue`}<br parentName="p"></br>{`
`}{`Without a cap.`}</p>
    <p>{`It also challenges you`}<br parentName="p"></br>{`
`}{`Sometimes you don’t know what to do`}<br parentName="p"></br>{`
`}{`But after a few or a bunch of tries`}<br parentName="p"></br>{`
`}{`You would understand`}<br parentName="p"></br>{`
`}{`That if you won’t do it`}<br parentName="p"></br>{`
`}{`Certainly, you will have to pay a price.`}</p>
    <p>{`If you want another example`}<br parentName="p"></br>{`
`}{`It is easy for me`}<br parentName="p"></br>{`
`}{`Let’s take my passion`}<br parentName="p"></br>{`
`}{`And it is my love to read.`}</p>
    <p>{`Reading books makes me feel`}<br parentName="p"></br>{`
`}{`Relaxed and challenged`}<br parentName="p"></br>{`
`}{`I travel in time where noone has gone`}<br parentName="p"></br>{`
`}{`And no book has ever made me run.`}</p>
    <p>{`If you can’t imagine yourself without your passion`}<br parentName="p"></br>{`
`}{`And it is what makes you original`}<br parentName="p"></br>{`
`}{`Even if you slip on your way`}<br parentName="p"></br>{`
`}{`Passion can be like the sun on a rainy day.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      